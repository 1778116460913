<template>
  <div>
    <div class="index-wrap" :style="{ background: backgroundColor }">
			<div class="index" style="width: 100%;padding-left: 0px;">
				<div class="banner">
					<el-carousel height="430px" arrow="hover" v-loading="loadingAd" @change="handleChange">
						<el-carousel-item v-for="item in adList" :key="item.adv_id">
							<el-image style="width:100%;height:100%;" :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" />
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>
    
    <div class="notice-wrap" style="background:#F5F5F5;">
      <el-breadcrumb separator="/" class="path" style="background:#fff;">
        <div style="max-width: 1210px;margin:0 auto;">
          <el-breadcrumb-item :to="{ path: '/' }" class="path-home">
            首页
          </el-breadcrumb-item>
          <el-breadcrumb-item class="path-help">联系我们</el-breadcrumb-item>
        </div>
      </el-breadcrumb>
      <div style="font-size:48px;color:#414141;text-align:center;background:#F5F5F5;">CONTACT US</div>
      <div style="font-size:30px;color:#414141;text-align:center;background:#F5F5F5;">联系我们</div>

      <div style="background:#F5F5F5;display:flex;justify-content: space-between;max-width: 1210px;margin:90px auto 20px auto;">
        <div style="height:311px;width:393px;background:#FFFFFF;box-shadow:0px 5px 5px 0px #EDEDED;text-align:center;">
          <div style="margin-top: -50px;">
            <img src="@/assets/images/newArrival/address.png" alt="" style="    border: 12px solid #fff; border-radius: 50%;">
          </div>
          <p style="color:#666666;font-size:18px;margin-top:15px;">公司地址</p>
          <p style="color:#666666;font-size:14px;margin-top:30px;">地址：{{address}}</p>
        </div>
        <div style="height:311px;width:393px;background:#FFFFFF;box-shadow:0px 5px 5px 0px #EDEDED;text-align:center;">
          <div style="margin-top: -50px;">
            <img src="@/assets/images/newArrival/dianhuaPhoneNumber.png" alt="" style="    border: 12px solid #fff; border-radius: 50%;">
          </div>
          <p style="color:#666666;font-size:18px;margin-top:15px;">服务热线</p>
          <p style="color:#666666;font-size:14px;margin-top:30px;">电话：{{telphone}}</p>
          <p style="color:#666666;font-size:14px;">邮箱：{{email}}</p>
        </div>
        <div style="height:311px;width:393px;background:#FFFFFF;box-shadow:0px 5px 5px 0px #EDEDED;text-align:center;">
          <div style="margin-top: -50px;">
            <img src="@/assets/images/newArrival/xiaochengxu.png" alt="" style="    border: 12px solid #fff; border-radius: 50%;">
          </div>
          <p style="color:#666666;font-size:18px;margin-top:15px;">服务热线</p>
          <div style="height:120px;width:120px;background:#DDDDDD;text-align:center;margin: 30px auto 0 auto;">
            <img :src="store_image" alt="" style="width:100%;height:100%">
          </div>
        </div>
      </div>
      <div style="height:50px;">

      </div>

      <div style="text-align:center;">

      </div>


      <!-- <div class="notice" v-loading="loading">
        <div class="menu">
          <div class="title">最新公告</div>
          <div class="item" v-for="item in noticeList" :key="item.id" @click="detil(item.id)">
            <div class="item-name">{{ item.title }}</div>
          </div>
        </div>
        <div class="list-wrap">
          <div class="notice-title">商城公告</div>
          <div class="list" v-for="item in noticeList" :key="item.id" @click="detil(item.id)">
            <div class="item">{{ item.title }}</div>
            <div class="info">
              <div class="time">{{ $util.timeStampTurnTime(item.create_time) }}</div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="pager">
          <el-pagination
        background
        :pager-count="5" 
        :total="total" 
        prev-text="上一页" 
        next-text="下一页" 
        :current-page.sync="queryinfo.page" 
        :page-size.sync="queryinfo.page_size"
        @size-change="handlePageSizeChange" 
        @current-change="handleCurrentPageChange" 
        hide-on-single-page
      ></el-pagination>
        </div>
    </div>


    <!-- <hello > </hello >  -->
    <baidu-map :center="{lng:this.longitude,lat:this.latitude} "  :zoom="17" :scroll-wheel-zoom="true" style="height:550px; width:1210px;margin: 0 auto 0 auto;">
      <bm-marker :position="{lng:this.longitude,lat:this.latitude}" :dragging="false" :icon="{url:require('@/assets/images/newArrival/address.png'),backgroundPosition:center, size: {width: 30, height: 30}}"  >
        
        </bm-marker> 
    </baidu-map>
    <!-- <baidu-map class="map" :center="markerPoint" :zoom="16"> 
        <bm-marker :position="markerPoint" :dragging="false" @click="infoWindowOpen">
            <bm-info-window :show="show" @close="infoWindowClose" @open="infoWindowOpen" style="font-size:50px">
                北京xxxx <br><br>
                地址：北京市
            </bm-info-window>
        </bm-marker> 
    </baidu-map> -->

    <div style="height:100px;"></div>
    


    




  </div>
</template>

<script>
import hello from "@/components/hello.vue";
import { noticesList } from "@/api/cms/notice";
import index from '@/views/index/_index/index.js';
export default {
  name: "notice",
  components: {
    hello
    },
  mixins: [index],
  data: () => {
    return {
      markerPoint:{lng: 116.4, lat: 39.9},
        show:false,

      queryinfo: {
        page: 1,
        page_size: 10,
		    receiving_type : "web"
      },
      noticeList: [],
      total: 0,
      loading: true,

      address:"",
      email:"",
      telphone:"",
      store_image:"",
      latitude:"",
      longitude:""
    };
  },
  created() {
    this.getList();
    var _this=this;

    _this.$ajax.post(
      `https://tengpaidoor.jiajushe.com.cn/api/Demo/lianxi`,
      _this.$qs.parse({
        // id:_this.category_id
      })
    )
    .then(e=> {
      _this.address=e.data.address
      _this.email=e.data.em
      _this.telphone=e.data.telphone
      _this.store_image=e.data.store_image
      _this.latitude=e.data.latitude
      _this.longitude=e.data.longitude
      // _this.lunboxtext=e.data
      // _this.richtext=e.data.content
      // _this.rongyupicture=e.data
      console.log("fdhgdhdfhdfhgfdgfd",e.data)
    })
    .catch(error=>{
      console.log(error)
    })
  },
  methods: {
    infoWindowClose () {
      this.show = false
    },
    infoWindowOpen () {
      this.show = true
    },




    detil(id) {
      this.$router.push({ path: "/cms/notice-" + id });
    },
    getList() {
      noticesList(this.queryinfo)
        .then(res => {
          if (res.code == 0 && res.data) {
            this.noticeList = res.data.list;
            this.total = res.data.count;
          }
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message);
        });
    },
    handlePageSizeChange(newsize) {
      this.queryinfo.page_size = newsize;
      this.getList();
    },
    handleCurrentPageChange(newnum) {
      this.queryinfo.page = newnum;
      this.getList();
    }
  }
};
</script>
<style lang="scss" scoped>

.map {
    width: 553px;
    height: 398px;
}

.notice-wrap {
  background: #ffffff;
  .path {
    padding: 15px 0;
  }
}
.notice {
  background-color: #ffffff;
  min-height: 300px;
  position: relative;
  display: flex;
  .menu {
    width: 210px;
    min-height: 300px;
    background: #ffffff;
    border: 1px solid #f1f1ff;
    flex-shrink: 0;
    .title {
      padding-left: 16px;
      background: #f8f8f8;
      font-size: $ns-font-size-base;
      height: 40px;
      cursor: pointer;
      color: #666666;
      display: flex;
      align-items: center;
    }
    .item-name {
      font-size: $ns-font-size-base;
      cursor: pointer;
      line-height: 40px;
      border-top: 1px solid #f1f1f1;
      padding-left: 25px;
      padding-right: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 40px;
      background: #ffffff;
      color: #666666;
      &:hover {
        color: $base-color;
      }
    }
    .active {
      color: $base-color;
    }
  }
  .list-wrap {
    padding: 0 40px;
    margin-left: 27px;
    border: 1px solid #f1f1f1;
    width: 100%;
    margin-bottom: 10px;
    .notice-title {
      padding: 37px 0 20px 0;
      font-size: 18px;
      border-bottom: 1px dotted #e9e9e9;
    }
    .list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:last-of-type {
        border-bottom: initial;
      }
      &:nth-child(2) {
        margin-top: 10px;
      }
      .item {
        font-size: $ns-font-size-base;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        padding: 3px 0;
        &:hover {
          color: $base-color;
        }
      }
      .info {
        display: flex;
        font-size: $ns-font-size-base;
        .time {
          margin-right: 10px;
        }
      }
    }
  }
}
  .page {
    text-align: center;
  }
</style>
